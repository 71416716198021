import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import qs from 'qs'
import * as util from './unit/network'
// import './unit/index'
Vue.prototype.$util = util;

import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

import '@icon-park/vue/styles/index.css'

Vue.prototype.$axios = axios
Vue.prototype.qs = qs

Vue.prototype.$fileUrl = 'https://safe.yunlearning.com/admin'
Vue.prototype.$imgUrl = 'https://safe.yunlearning.com'

import global from './unit/cookie'
Vue.prototype.$global = global

import echarts from "echarts";
Vue.prototype.$echarts = echarts;

import htmlToPdf from './units/htmlToPdf'
Vue.use(htmlToPdf)

import hlsjs from 'hls.js'
Vue.prototype.$hlsjs = hlsjs;

var deviceWidth
setHtmlFontSize()

if (window.addEventListener) {
  window.addEventListener('resize', function () {
    setHtmlFontSize()
  }, false)
}

function setHtmlFontSize() {
  // 1366是设计稿的宽度，当大于1366时采用1366宽度，比例也是除以13.66
  deviceWidth = document.documentElement.clientWidth <= 1280 ? 1280 : document.documentElement.clientWidth
  // deviceWidth = document.documentElement.clientWidth
  document.getElementsByTagName('html')[0].style.cssText = 'font-size:' + deviceWidth / 19.20 + 'px !important'
}




Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')