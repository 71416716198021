import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    count: 0,
    num: 1,
    focusPath: '/',
    fromPath: '/main',
    fromQuery:"",
    urlimg: '',
    cover: "",
    spaceId: 1,
    userName:"",
    userLogo:"",
    isDataEncryption:false,
  },
  getters: {},
  mutations: {
    encryptionChange(state, index) {
      state.isDataEncryption = index
    },
    userNameChange(state, index) {
      state.userName = index
    },
    userLogoChange(state, index) {
      state.userLogo = index
    },
    pathChange(state, index) {
      state.focusPath = index
    },
    fromPathChange(state, index) {
      state.fromPath = index
    },
    fromQueryChange(state, index) {
      state.fromQuery = index
    },
    urlChange(state, index) {
      state.urlimg = index
    },
    imgChange(state, index) {
      state.cover = index
    },
    spaceChange(state, index) {
      state.spaceId = index
    },
  },
  actions: {},
  modules: {}
})