<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    if (sessionStorage.getItem("store")) {
      //this.$store.replaceState是vue官方提供的一个api表示替换 store 的根状态
      //里面的Object.assign()表示将store中的状态和sessionStorage中的状态进行合并
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    //beforeunload表示当浏览器窗口关闭或者刷新时，会触发beforeunload事件
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
li {
  list-style: none;
}
html {
  font-size: 100px;
}
body {
  font-size: 14px;
}
#app {
  width: 100%;
  min-width: 1280px;
  // height: 100vh;
  // min-height: 720px;
  min-height: 100vh;
  background-color: #eefefb;
  // height: 1080px;
  font-family: PingFang SC, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // @media screen and (min-width: 1900px) and (max-width: 1920px) {
  //   height: 937px;
  // }
}

.contain > header {
  display: flex;
  flex-direction: column;
  color: #333;
  border-bottom: 0.01rem solid #e0e4ec;
  .title {
    font-size: 0.18rem;
    font-weight: 900;
    margin-bottom: 0.14rem;
    display: flex;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
    }
    img {
      width: 0.14rem;
      margin-right: 0.18rem;
    }
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0.06rem;
    ul {
      display: flex;
      align-items: center;
      font-size: 0.16rem;
      .el-divider--vertical {
        width: 0.03rem;
        margin: 0 0.33rem;
      }
      li {
        border-bottom: 0.03rem solid transparent;
        padding: 0.2rem 0.02rem;
        cursor: pointer;
        &:hover {
          color: #2983ff;
        }
      }
      li.active {
        color: #2983ff;
        border-bottom: 0.03rem solid #2983ff;
      }
    }
    .rBox {
      display: flex;
      align-items: center;

      /deep/.el-input__inner {
        height: 0.38rem;
      }
      .z {
        width: 0.14rem;
      }
      /deep/.el-button--primary {
        width: 0.95rem;
        font-size: 0.14rem;
        height: 0.34rem;
      }
    }
  }
}

table {
  width: 100%;
  border-spacing: 0;
  > thead {
    & > tr:hover {
      background: #f5f5f5;
    }
    > tr {
      & > td {
        font-size: 0.14rem;
        color: #333333;
        // padding: 0.26rem 0;
        height: 0.64rem;
        line-height: 0.64rem;
        border-bottom: 1px solid #e0e4ec;
      }
      & > td:nth-child(1) {
        padding-left: 0.16rem;
        //   min-width: 1.8rem;
      }
      //   & > td:nth-child(2) {
      //     min-width: 4rem;
      //   }
      //   & > td:nth-child(3) {
      //     min-width: 3.25rem;
      //   }
      //   & > td:nth-child(4) {
      //     min-width: 2.94rem;
      //   }
      //   & > td:nth-child(5) {
      //     min-width: 2.52rem;
      //   }
      //   & > td:nth-child(6) {
      //     min-width: 1.32rem;
      //   }
    }
  }
  .icon:hover {
    color: #2983ff;
  }
  i {
    cursor: pointer;
  }
  > tbody {
    & > tr:nth-child(2n-1) {
      background-color: #f5f9ff;
    }
    & > tr:hover {
      background: #f5f5f5;
    }
    > tr {
      > td {
        height: 0.5rem;
        box-sizing: border-box;
        border-bottom: 1px solid #e0e4ec;
        font-size: 0.14rem;
        color: #606266;
      }
      & > td:nth-child(1) {
        box-sizing: border-box;
        padding-left: 0.16rem;
      }
      & > td:last-child {
        display: flex;
        align-items: center;
      }
    }
  }
}
.el-input {
  font-size: 12px !important;
}
.el-button {
  // padding: 0;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.el-button--warning.is-plain {
  color: #ffac09 !important;
}
.el-button--warning.is-plain:hover {
  color: #fff !important;
  background: #ffac09 !important;
}
.el-button--warning.is-plain:focus {
  color: #fff !important;
  background: #ffac09 !important;
}
.el-button--success.is-plain {
  color: #22cc6f !important;
}
.el-button--success.is-plain:hover {
  color: #fff !important;
  background: #22cc6f !important;
}
.el-button--success.is-plain:focus {
  color: #fff !important;
  background: #22cc6f !important;
}
.el-button--primary.is-plain {
  color: #2983ff !important;
}
.el-button--primary.is-plain:hover {
  color: #fff !important;
  background: #2983ff !important;
}
.el-button--primary.is-plain:focus {
  color: #fff !important;
  background: #2983ff !important;
}

.el-button--primary.bl {
  background-color: #2983ff !important;
  border-color: #2983ff !important;
}

.el-message-box__btns .el-button.el-button--default.el-button--small {
  width: 0.5rem;
  height: 0.28rem;
  box-sizing: border-box;
}

.line1 {
  white-space: nowrap; //禁止换行
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis//显示省略号;
}
.line2 {
  -webkit-line-clamp: 2; //限制文字显示行数
  display: -webkit-box; //将对象作为伸缩的盒子显示
  -webkit-box-orient: vertical; //盒子内子对象排列方式
  overflow: hidden;
}
.el-message-box__btns {
  display: flex;
  justify-content: flex-end;
}
.el-button--primary:hover {
  background-color: rgba(#2983ff, 0.8) !important;
}

.modelBox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  .box {
    width: 8.98rem;
    height: 5.94rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.16);
    border-radius: 0.04rem;
    padding: 0 0.3rem 0.4rem;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.18rem;
      font-weight: 900;
      height: 0.76rem;
      border-bottom: solid 1px #dcdfe6;
    }
    .modelMain {
      flex: 1;
      // background-color: #2983ff;
      video {
        width: 100%;
      }
    }
  }
}
// #about > .contain > .rightBox > .bottom {
//   position: relative;
// }
.w-e-full-screen-container {
  z-index: 999 !important;
}

.el-loading-mask.is-fullscreen{
  .el-icon-loading{
    font-size: .4rem;
  }
  .el-loading-text{
    font-size: .16rem;

  }
}
</style>
