<template>
  <div id="home">
    <div class="listBox">
      <div>
        <div class="logobox">
          <div class="img" v-show="!isCollapse">
            <img src="../img/1/logo.png" alt="" />
          </div>
          <div v-show="isCollapse">
            <img src="../img/1/icon_s.png" alt="" />
          </div>
        </div>
        <el-menu
          :default-active="focusPath"
          :unique-opened="true"
          :class="{ listBoxW: isCollapse == true }"
          router
          @open="handleOpen"
          @close="handleClose"
          @select="handleSelect"
          :collapse="isCollapse"
          :collapse-transition="false"
        >
          <div v-for="(i, v) in nav_Menu" :key="v">
            <el-menu-item :index="i.url" class="wb" v-if="i.type == 1">
              <div class="ll" :class="{ llw: isCollapse }"></div>
              <!-- <div class="menuline"></div> -->
              <div class="siconImg">
                <!-- <img :src="i.img" alt="" /> -->
                <img :src="'https://safe.yunlearning.com' + i.img" alt="" />
              </div>
              <span slot="title">{{ i.name }}</span>
            </el-menu-item>

            <el-submenu :index="v + 1 + ''" v-if="i.type != 1">
              <template slot="title">
                <div
                  class="ll"
                  v-if="i.type != 1"
                  :class="{ llw: isCollapse }"
                ></div>
                <div class="siconImg" :class="{ llw1: isCollapse }">
                  <!-- <img :src="i.img" alt="" /> -->
                  <img :src="'https://safe.yunlearning.com' + i.img" alt="" />
                </div>
                <span
                  v-show="!isCollapse"
                  slot="title"
                  :class="{ titlestyle: titlestyle == v + 1 }"
                  >{{ i.name }}</span
                >
              </template>
              <el-menu-item-group>
                <el-menu-item
                  :index="item.url"
                  v-for="(item, index) in i.children"
                  :key="index"
                  style="margin: 0 0 0.14rem 0"
                >
                  <div class="ll ll2" v-if="i.type != 1"></div>
                  <!-- <i></i> -->
                  <span>{{ item.name }}</span>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </div>
        </el-menu>
      </div>
    </div>

    <div class="mainBox">
      <div class="top">
        <div class="topl">
          <div class="menuTip" @click="menuIsShow">
            <img v-if="isCollapse" src="@/img/1/righticon.png" alt="" />
            <img v-else src="@/img/1/lefticon.png" alt="" />
          </div>
          <span>{{ title }}</span>
        </div>
        <div class="Main_admin">
          <div class="img">
            <img
              :src="
                userLogo
                  ? 'https://safe.yunlearning.com' + userLogo
                  : require('@/img/1/headImg.png')
              "
              alt=""
            />
          </div>
          <el-dropdown>
            <span class="el-dropdown-link">
              {{ userName }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="goInfo()"
                >个人信息</el-dropdown-item
              >
              <el-dropdown-item @click.native="outBtn()"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="bottom">
        <router-view />
      </div>
      <div class="copyright">
        <div class="text">
          版权所属 2011-2020 简意科技 All Rights Reserved豫ICP备17033670号
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "HomeView",
  data() {
    return {
      titlestyle: "",
      title: "",
      nav_Menu: "",
      isCollapse: false,
      viewName: "控制台",
      // logo: "",
      // username: "",
    };
  },
  created() {
    axios.post("https://safe.yunlearning.com/index/test/IsStart").then((res) => {
      if (res.data) {
        this.$store.commit("encryptionChange", true)
      } else {
        this.$store.commit("encryptionChange", false)
      }
    })

    this.$util.post(
      "/admin/AdminIndex",
      { id: localStorage.getItem("userId") },
      (res) => {
        // this.logo = res.data.logo;
        // this.username = res.data.username;
        this.$store.commit("spaceChange", res.data.space_id);
        this.$store.commit("userNameChange", res.data.username);
        this.$store.commit("userLogoChange", res.data.logo);
      }
    );
  },
  mounted() {
    // this.$store.commit("pathChange", '/main');
    // this.$util.post(
    //   "/admin/AdminIndex",
    //   { id: localStorage.getItem("userId") },
    //   (res) => {
    //     console.log(res);
    //     this.logo = res.data.logo;
    //     this.username = res.data.username;
    //     this.$global.cookie.set("space_id", res.data.space_id);
    //   }
    // );
    this.$util.post("/auth/AuthIndex", {}, (res) => {
      console.log(res);
      this.nav_Menu = res.data;
    });
    switch (this.$route.path) {
      case "/main":
        this.title = "控制台";
        this.$store.commit("pathChange", "/main");
        break;
        case "/personalInfo":
          this.title = "个人信息";
          break;
      case "/userList":
        this.title = "用户列表";
        break;
      case "/userOrganization":
        this.title = "用户组织";
        break;
      case "/archives/info":
        this.title = "培训档案";
        this.$store.commit("pathChange", "/userList");
        break;
      case "/spacelist":
        this.title = "空间列表";
        break;
      case "/spaceapply":
        this.title = "空间申请";
        break;
      case "/resource":
        this.title = "资源列表";
        break;
      case "/accident":
        this.title = "事故案例";
        break;
      case "/courselist":
        this.title = "课程列表";
        break;
      case "/coursetype":
        this.title = "课程类型";
        break;
      case "/certificate":
        this.title = "证书管理";
        break;
      case "/topic":
        this.title = "题库管理";
        break;
      case "/exam":
        this.title = "考试管理";
        break;
      case "/newsBulletin":
        this.title = "新闻公告";
        break;
      case "/datauser":
        this.title = "数据统计";
        break;
      case "/systemSet":
        this.title = "系统设置";
        break;
      default:
        this.title = "数据大屏";
    }
  },
  methods: {
    goInfo() {
      this.$router.push({
        path: "/personalInfo",
      });
    },
    outBtn() {
      this.$store.commit("pathChange", "/main");
      localStorage.removeItem("userId");
      this.$global.cookie.delete("token");
      this.$router.push({
        path: "/login/logins",
      });
    },
    menuIsShow() {
      this.isCollapse = !this.isCollapse;
    },
    handleOpen(key, keyPath) {
      this.titlestyle = key;
      console.log("key", key, "keypath", keyPath);
    },
    handleClose(key, keyPath) {
      this.titlestyle = "";
      console.log(key, keyPath);
    },
    handleSelect() {
      this.titlestyle = "";
    },
  },
  components: {},
  computed: {
    // count() {
    //   return this.$store.state.count;
    // },
    ...mapState(["focusPath","userName","userLogo"]),
  },
  // computed: mapState({
  //   count(state) {
  //     return state.count;
  //   },
  // }),
  watch: {
    $route(to, from) {
      console.log(to, from, "router");
      // if (to.path == "/" || to.path == "/menu") {
      // this.focusPath = to.path;
      this.$store.commit("fromPathChange", from.path);
      this.$store.commit("fromQueryChange", from.query);
      this.$store.commit("pathChange", to.path);
      // } else if (to.path == "/equipment") {
      //   this.$store.commit("changeLmenu", 2);
      // } else if (to.path == "/demonstration") {
      //   this.$store.commit("changeLmenu", 3);
      // } else if (to.path == "/principle") {
      //   this.$store.commit("changeLmenu", 4);
      // }
      switch (this.$route.path) {
        case "/main":
          this.title = "控制台";
          break;
          case "/personalInfo":
          this.title = "个人信息";
          break;
        case "/userList":
          this.title = "用户列表";
          break;
        case "/user/add":
          this.title = "新增用户";
          this.$store.commit("pathChange", "/userList");
          break;
        case "/administrator/add":
          this.title = "新增管理员";
          this.$store.commit("pathChange", "/userList");
          break;
        case "/archives/info":
          this.title = "培训档案";
          this.$store.commit("pathChange", "/userList");
          break;
        case "/userOrganization":
          this.title = "新增管理员";
          break;
        case "/team/list":
          this.title = "班组列表";
          this.$store.commit("pathChange", "/userOrganization");
          break;
        case "/spacelist":
          this.title = "空间列表";
          break;
        case "/space/add":
          this.title = "添加列表";
          this.$store.commit("pathChange", "/spacelist");
          break;
        case "/spaceinfos":
          this.title = "空间详情";
          this.$store.commit("pathChange", "/spacelist");
          break;
        case "/spacestu":
          this.title = "空间详情";
          this.$store.commit("pathChange", "/spacelist");
          break;
        case "/spaceclass":
          this.title = "空间详情";
          this.$store.commit("pathChange", "/spacelist");
          break;
        case "/spaceclass/infos":
          this.title = "空间详情";
          this.$store.commit("pathChange", "/spacelist");
          break;
        case "/spaceresource":
          this.title = "空间详情";
          this.$store.commit("pathChange", "/spacelist");
          break;
        case "/spaceapply":
          this.title = "空间申请";
          break;
        case "/resource":
          this.title = "资源列表";
          break;
        case "/resource/add":
          this.title = "资源上传";
          this.$store.commit("pathChange", "/resource");
          break;
        case "/accident":
          this.title = "事故案例";
          break;
        case "/accident/add":
          this.title = "添加案例";
          this.$store.commit("pathChange", "/accident");
          break;
        case "/policy/add":
          this.title = "添加政策";
          this.$store.commit("pathChange", "/accident");
          break;
        case "/courselist":
          this.title = "课程列表";
          break;
        case "/census":
          this.title = "课程列表";
          this.$store.commit("pathChange", "/courselist");
          break;
        case "/courselist/add":
          this.title = "课程列表";
          this.$store.commit("pathChange", "/courselist");
          break;
        case "/courselist/add2":
          this.title = "课程列表";
          this.$store.commit("pathChange", "/courselist");
          break;
        case "/courselist/add3":
          this.title = "课程列表";
          this.$store.commit("pathChange", "/courselist");
          break;
        case "/homework/edit":
          this.title = "课程列表";
          this.$store.commit("pathChange", "/courselist");
          break;
        case "/homework/add":
          this.title = "课程列表";
          this.$store.commit("pathChange", "/courselist");
          break;
        case "/homework/add1":
          this.title = "课程列表";
          this.$store.commit("pathChange", "/courselist");
          break;
        case "/archives/info1":
          this.title = "培训档案";
          this.$store.commit("pathChange", "/courselist");
          break;
        case "/coursetype":
          this.title = "课程类型";
          break;
        case "/certificate":
          this.title = "证书管理";
          break;
        case "/certificate/add":
          this.title = "课程列表";
          this.$store.commit("pathChange", "/certificate");
          break;
        case "/topic":
          this.title = "题库管理";
          break;
        case "/topic/add":
          this.title = "资源上传";
          this.$store.commit("pathChange", "/topic");
          break;
        case "/exam":
          this.title = "考试管理";
          break;
        case "/exam/add":
          this.title = "添加考试";
          this.$store.commit("pathChange", "/exam");
          break;
        case "/exam/tj":
          this.title = "考试管理";
          this.$store.commit("pathChange", "/exam");
          break;
        case "/exam/info":
          this.title = "考试管理";
          this.$store.commit("pathChange", "/exam");
          break;
        case "/exam/add1":
          this.title = "添加考试";
          this.$store.commit("pathChange", "/exam");
          break;
        case "/exam/adds":
          this.title = "添加考试";
          this.$store.commit("pathChange", "/exam");
          break;
        case "/exam/topiclist":
          this.title = "添加考试";
          this.$store.commit("pathChange", "/exam");
          break;
        case "/exam/sort":
          this.title = "查看试卷";
          this.$store.commit("pathChange", "/exam");
          break;
        case "/newsBulletin":
          this.title = "新闻公告";
          break;
        case "/bulletin/add":
          this.title = "添加公告";
          this.$store.commit("pathChange", "/newsBulletin");
          break;
        case "/datauser":
          this.title = "数据统计";
          break;
        case "/dataspace":
          this.title = "数据统计";
          this.$store.commit("pathChange", "/datauser");
          break;
        case "/dataclasses":
          this.title = "数据统计";
          this.$store.commit("pathChange", "/datauser");
          break;
        case "/datauser/infos":
          this.title = "数据统计";
          this.$store.commit("pathChange", "/datauser");
          break;
        case "/dataping":
          this.title = "数据统计";
          this.$store.commit("pathChange", "/datauser");
          break;
        case "/systemSet":
          this.title = "系统设置";
          break;
        case "/shuffling/add":
          this.title = "数据统计";
          this.$store.commit("pathChange", "/systemSet");
          break;
        default:
          this.title = "数据大屏";
      }
    },
    // focusPath(newValue, oldValue) {
    //   console.log(newValue, oldValue);
    // },
  },
};
</script>
<style lang="less" scoped>
#home {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #eef2fb;
  display: flex;

  .listBox {
    padding-top: 0.84rem;
    // width: 2rem;
    // height: 100%;
    // min-height: 100vh;
    background-color: #fff;
    position: relative;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
    .logobox {
      position: absolute;
      top: 0.1rem;
      left: 0;
      height: 0.84rem;
      width: 100%;
      // background: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 99;
      .img {
        img {
          width: 100%;
        }
      }
    }
    /deep/.el-icon-arrow-right:before {
      content: "";
    }
    .ll {
      display: inline-block;
      width: 0.05rem;
      height: 0.4rem;
      background-color: transparent;
      border-radius: 0 0.05rem 0.05rem 0px;
      margin-right: 0.28rem;
    }
    .llw {
      position: absolute;
      left: 0rem;
      top: 0;
      margin-right: 0.02rem;
    }
    .llw1 {
      width: 0.24rem;
      height: 0.24rem;
      position: relative;
      left: 0.18rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .ll2 {
      margin-right: 0.66rem;
    }
    .el-menu {
      width: 2.32rem;
      // width: 2rem;
      // width: 200px;
      min-height: calc(100vh - 0.84rem);
      height: 100%;
      box-sizing: border-box;
      padding-right: 0.1rem;
      margin-left: 0.1rem;
      border: 0;
    }
    .el-menu-item {
      display: flex;
      align-items: center;
      height: 0.4rem;
      line-height: 0.4rem;
      font-size: 0.14rem;
      font-weight: 500;
      color: #000;
      // padding: 0 0.04rem !important;
      padding: 0 0 !important;
      margin: 0.16rem 0;
    }
    /deep/.el-submenu .el-menu-item:last-child {
      margin-bottom: 0 !important;
    }
    /deep/.el-icon-arrow-down::before {
      font-size: 0.14rem;
      padding: 0.08rem;
    }
    /deep/.siconImg {
      // width: 0.24rem;
      // height: 0.24rem;
      width: 0.18rem;
      height: 0.18rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.18rem;
      // background-color: antiquewhite;
      img {
        width: 100%;
        // height: 100%;
      }
    }

    .el-menu-item.is-active {
      background: #e3ebf6;
      .ll {
        background-color: #2983ff;
      }
    }

    /deep/.el-menu-item-group__title {
      padding: 0 !important;
    }
    /deep/.el-submenu__title {
      height: 0.4rem;
      line-height: 0.4rem;
      font-size: 0.14rem;
      // color: #000;
      display: flex;
      align-items: center;
      padding: 0 0rem !important;
      margin: 0.18rem 0;
      .titlestyle {
        font-weight: 900;
        color: #2983ff !important;
      }
    }
    .el-menu-item.wb.is-active {
      font-weight: 900;
      color: #2983ff;
    }
    .el-submenu {
      margin: 0.08rem 0;
    }
    .el-submenu .el-menu-item {
      min-width: auto;
      padding: 0 !important;
    }
    // .el-menu-item.is-active {
    //   font-weight: 500;
    //   // color: #000;
    // }
    /deep/.el-submenu.is-active.is-opened .el-submenu__title {
      font-weight: 900;
      color: #2983ff !important;
    }

    /deep/.el-tooltip {
      padding: 0 !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      .siconImg {
        margin: 0;
      }
    }
    .tip {
      /deep/.el-submenu__title {
        width: 100%;
        display: flex !important;
        align-items: center;
        justify-content: center;
      }

      .siconImg {
        margin: 0;
      }
    }
  }
  .listBoxW {
    width: 64px !important;
  }
  .mainBox {
    flex: 1;
    // width: flex;
    display: flex;
    flex-direction: column;

    .top {
      width: 100%;
      height: 56px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.24rem 0 0.28rem;
      > div {
        display: flex;
        align-items: center;
      }
      .topl {
        display: flex;
        align-items: center;
        .menuTip {
          display: flex;
          align-items: center;
          width: 0.22rem;
          margin-right: 0.06rem;
          cursor: pointer;
          img {
            width: 100%;
          }
        }
        span {
          font-size: 0.16rem;
        }
      }
      .Main_admin {
        .img {
          width: 0.38rem;
          height: 0.38rem;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 0.1rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .el-dropdown-link {
          cursor: pointer;
        }
      }
    }
    .bottom {
      flex: 1;
      background-color: #eef2fb;
      // padding: 0.16rem 0.26rem;
      position: relative;
      > div {
        padding: 0.16rem 0.26rem;
      }
    }
    .copyright {
      height: 0.34rem;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .text {
        font-size: 0.12rem;
        color: #5f5f5f;
      }
    }
  }
}
.loading {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: conic-gradient(deepskyblue, 30%, white);
  --mask: radial-gradient(closest-side, transparent 75%, black 76%);
  -webkit-mask-image: var(--mask);
  mask-image: var(--mask);
  animation: spin 1s linear infinite reverse;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
