import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [{
    path: '/',
    // name: 'Login',
    component: () => import('@/views/LoginView'),
    children: [
      // 登录主体
      {
        path: '/',
        component: () => import('@/components/login/Logins')
      },
      // 登录页
      {
        path: '/Login/Logins',
        component: () => import('@/components/login/Logins')
      },
      // 注册页
      // { path: '/Login/Register', component: ()=>import('@/components/login/Register.vue') },
    ]
  },
  {
    path: '/main',
    component: HomeView,
    children: [{
        path: '/main',
        component: () => import('@/views/AboutView'),
      },
      // 用户管理
      {
        path: '/userList',
        component: () => import('@/views/userManage/userList')
      }, {
        path: '/userOrganization',
        component: () => import('@/views/userManage/userOrganization')
      }, {
        path: '/user/add',
        component: () => import('@/components/userManage/userAdd')
      }, {
        path: '/administrator/add',
        component: () => import('@/components/userManage/administratorAdd')
      }, {
        path: '/archives/info',
        component: () => import('@/components/userManage/archivesInfo')
      },{
        path: '/archives/info1',
        component: () => import('@/views/courseManage/archivesInfo')
      }, {
        path: '/team/list',
        component: () => import('@/components/userManage/teamList')
      },
      // 空间管理
      {
        path: '/spacelist',
        component: () => import('@/views/space/spaceList')
      },
      {
        path: '/space/add',
        component: () => import('@/views/space/spaceAdd')
      },
      {
        path: '/spaceapply',
        component: () => import('@/views/space/spaceApply')
      },
      {
        path: '/spaceapply/info',
        component: () => import('@/views/space/spaceApplyInfos')
      },
      {
        path: '/spaceinfos',
        component: () => import('@/views/space/spaceInfos')
      },
      {
        path: '/spacestu',
        component: () => import('@/views/space/spaceStudent')
      },
      {
        path: '/spaceclass',
        component: () => import('@/views/space/spaceClass')
      },
      {
        path: '/spaceresource',
        component: () => import('@/views/space/spaceResource')
      },
      {
        path: '/spaceclass/infos',
        component: () => import('@/views/space/spaceClassInfos')
      },
      // 空间管理

      // 考试管理
      {
        path: '/exam',
        component: () => import('@/views/ExamView')
      },
      {
        path: '/exam/topiclist',
        component: () => import('@/components/exam/ExamTopicList')
      },
      {
        path: '/exam/tj',
        component: () => import('@/components/exam/ExamTj')
      },
      {
        path: '/exam/add',
        component: () => import('@/components/exam/ExamAdd')
      }, {
        path: '/exam/add1',
        component: () => import('@/components/exam/ExamAdd1')
      }, {
        path: '/exam/adds',
        component: () => import('@/components/exam/ExamAdd1_1')
      }, {
        path: '/exam/sort',
        component: () => import('@/components/exam/ExamSort')
      }, {
        path: '/exam/info',
        component: () => import('@/components/exam/ExamInfo')
      },

      // 资源管理
      {
        path: '/resource',
        component: () => import('@/views/resourceManage/resourceView')
      }, {
        path: '/safety',
        component: () => import('@/views/resourceManage/safetyView')
      }, {
        path: '/accident',
        component: () => import('@/views/resourceManage/accidentView')
      }, {
        path: '/resource/add',
        component: () => import('@/components/resourceManage/resourceAdd')
      }, {
        path: '/safety/add',
        component: () => import('@/components/resourceManage/safetyAdd')
      }, {
        path: '/accident/add',
        component: () => import('@/components/resourceManage/accidentAdd')
      }, {
        path: '/policy/add',
        component: () => import('@/components/resourceManage/policyAdd')
      },
      // 资源管理
      // 课程管理
      {
        path: '/courselist',
        component: () => import('@/views/courseManage/courseList')
      }, {
        path: '/courselistxt',
        component: () => import('@/views/courseManage/courseListXt')
      }, {
        path: '/coursetype',
        component: () => import('@/views/courseManage/courseType')
      }, {
        path: '/certificate',
        component: () => import('@/views/courseManage/courseCertificate')
      }, {
        path: '/certificate/add',
        component: () => import('@/views/courseManage/certificateAdd')
      }, {
        path: '/courselist/add',
        component: () => import('@/views/courseManage/courselistAdd')
      }, {
        path: '/courselist/add2',
        component: () => import('@/views/courseManage/courselistAdd2')
      }, {
        path: '/courselist/add3',
        component: () => import('@/views/courseManage/courselistAdd3')
      }, {
        path: '/courselist/watch',
        component: () => import('@/views/courseManage/courselistWatch')
      }, {
        path: '/census',
        component: () => import('@/views/courseManage/censusView')
      }, {
        path: '/homework/edit',
        component: () => import('@/views/courseManage/editHomework')
      }, {
        path: '/homework/add',
        component: () => import('@/views/courseManage/homeworkAdd')
      }, {
        path: '/homework/add1',
        component: () => import('@/views/courseManage/homeworkAdd1')
      },

      // 课程管理

      // 数据统计
      {
        path: '/datauser',
        component: () => import('@/views/data/dataUser')
      },
      {
        path: '/datauser/infos',
        component: () => import('@/views/data/dataUserInfo')
      },
      {
        path: '/dataspace',
        component: () => import('@/views/data/dataSpace')
      },
      {
        path: '/dataclasses',
        component: () => import('@/views/data/dataClasses')
      },
      {
        path: '/dataping',
        component: () => import('@/views/data/dataPing')
      },
      // 数据统计

      //新闻公告
      {
        path: '/newsBulletin',
        component: () => import('@/views/newsBulletin/newsBulletin')
      }, {
        path: '/bulletin/add',
        component: () => import('@/views/newsBulletin/bulletinAdd')
      },
      //系统设置
      {
        path: '/systemSet',
        component: () => import('@/views/systemSet/systemSet')
      }, {
        path: '/shuffling/add',
        component: () => import('@/views/systemSet/shufflingAdd')
      },
      {
        path: '/shuffling/infos',
        component: () => import('@/views/systemSet/fkInfos')
      },
      //题库管理
      {
        path: '/topic',
        component: () => import('@/views/topicManage/topicManage')
      }, {
        path: '/topic/add',
        component: () => import('@/views/topicManage/topicAdd')
      },
      //个人信息
      {
        path: '/personalInfo',
        component: () => import('@/views/personalInfo')
      },
    ]
  },
  {
    path: '/visual',
    component: () => import('@/views/VisualView')
  }
]

const router = new VueRouter({
  routes
})

export default router